define("discourse/plugins/discourse-siwt/discourse/templates/siwt-auth-index", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    {{!
   SPDX-FileCopyrightText: Copyright (C) 2022, vDL Digital Ventures GmbH <info@vdl.digital>
   SPDX-FileCopyrightText: Copyright (C) 2021, Spruce Systems, Inc.
    
   SPDX-License-Identifier: MIT
  *\/ }}
  
  <form id="siwt-sign" method="POST" action="/auth/siwt/callback">
    <textarea id="tezos_account" name="tezos_account"></textarea>
    <textarea id="tezos_message" name="tezos_message"></textarea>
    <textarea id="tezos_signature" name="tezos_signature"></textarea>
    <textarea id="tezos_public_key" name="tezos_public_key"></textarea>
  </form>
  <div class="tezos_provider_container">
    <div class="tezos_provider_auth_button">
      {{d-button
        class="btn-primary"
        action=(action "initAuth")
        label="tezos_providers.buttons.start"
      }}
    </div>
  </div>
  */
  {
    "id": "waMMbb1f",
    "block": "[[[1,\"\\n\"],[10,\"form\"],[14,1,\"siwt-sign\"],[14,\"method\",\"POST\"],[14,\"action\",\"/auth/siwt/callback\"],[12],[1,\"\\n  \"],[10,\"textarea\"],[14,1,\"tezos_account\"],[14,3,\"tezos_account\"],[12],[13],[1,\"\\n  \"],[10,\"textarea\"],[14,1,\"tezos_message\"],[14,3,\"tezos_message\"],[12],[13],[1,\"\\n  \"],[10,\"textarea\"],[14,1,\"tezos_signature\"],[14,3,\"tezos_signature\"],[12],[13],[1,\"\\n  \"],[10,\"textarea\"],[14,1,\"tezos_public_key\"],[14,3,\"tezos_public_key\"],[12],[13],[1,\"\\n\"],[13],[1,\"\\n\"],[10,0],[14,0,\"tezos_provider_container\"],[12],[1,\"\\n  \"],[10,0],[14,0,\"tezos_provider_auth_button\"],[12],[1,\"\\n    \"],[1,[28,[35,3],null,[[\"class\",\"action\",\"label\"],[\"btn-primary\",[28,[37,4],[[30,0],\"initAuth\"],null],\"tezos_providers.buttons.start\"]]]],[1,\"\\n  \"],[13],[1,\"\\n\"],[13]],[],false,[\"form\",\"textarea\",\"div\",\"d-button\",\"action\"]]",
    "moduleName": "discourse/plugins/discourse-siwt/discourse/templates/siwt-auth-index.hbs",
    "isStrictMode": false
  });
});